import React from 'react'
import { useLanguage } from '../../ContextProvider/Language';
import { schedule } from '../../Assets';

const WhyUs = () => {
   const { selectedLanguage, changeLanguage } = useLanguage();
   const text = {
     text1: [
       'Why Choose Us',
       'ஏன் எங்களை தேர்வு செய்யவேண்டும்?',
       'අපව තෝරා ගන්නේ ඇයි?',
     ],
     text2: [
       '  Choose us for a ride that blends Easy Searching, Quick Pickups,Inclusive Rates, and a touch of innovation. Join us on the road to exceptional travel experiences',
       'எளிதான தேடுதல், விரைவான பிக்அப்கள், உள்ளடக்கிய விலைகள் மற்றும் புதுமையின் தொடுதல் ஆகியவற்றைக் கொண்ட சவாரிக்கு எங்களைத் தேர்வுசெய்யவும். விதிவிலக்கான பயண அனுபவங்களுக்கான பாதையில் எங்களுடன் சேருங்கள்',
       'පහසු සෙවීම, ඉක්මන් පිකප්, ඇතුළත් මිල ගණන් සහ නව්‍යකරණයේ ස්පර්ශය මුසු කරන සවාරියක් සඳහා අපව තෝරා ගන්න. සුවිශේෂී සංචාරක අත්දැකීම් සඳහා මාවතේ අප හා එක්වන්න',
     ],
   };
     const data = [
       {
         text1: ['Safety Guarantee', 'பாதுகாப்பு உத்தரவாதம்', 'ආරක්ෂිත සහතිකය'],
         text2: [
           'Your well-being is our priority. every ride is backed by rigorous safety protocols',
           'உங்கள் நலமே எங்கள் முன்னுரிமை. ஒவ்வொரு சவாரியும் கடுமையான பாதுகாப்பு நெறிமுறைகளால் ஆதரிக்கப்படுகிறது',
           'ඔබගේ යහපැවැත්ම අපගේ ප්‍රමුඛතාවයයි. සෑම ගමනක්ම දැඩි ආරක්ෂණ ප්‍රොටෝකෝල මගින් අනුබල දෙනු ලැබේ',
         ],
         icon: <i class='ri-shield-flash-line'></i>,
       },
       {
         text1: [
           'Trusted & Verified Driver',
           'Trusted & Verified Driver',
           'Trusted & Verified Driver',
         ],
         text2: [
           ' Our drivers are not just , Trusted skilled professionals they are Verified',
           'Our drivers are not just , Trusted skilled professionals they are Verified',
           'Our drivers are not just , Trusted skilled professionals they are Verified',
         ],
         icon: <i class='ri-taxi-line'></i>,
       },
       {
         text1: ['Free Quotation', 'இலவச மேற்கோள்', 'නොමිලේ උපුටා ගැනීම'],
         text2: [
           ' Enjoy the Free Quotation  know the cost  before you even step into the vehicle',
           'இலவச மேற்கோளை அனுபவிக்கவும். நீங்கள் வாகனத்தில் ஏறுவதற்கு முன்பே விலையை அறிந்து கொள்ளுங்கள்',
           'නොමිලේ උපුටා ගැනීම භුක්ති විඳින්න. ඔබ වාහනයට නැගීමට පෙර වියදම දැන ගන්න',
         ],
         icon: <i class='ri-check-double-line'></i>,
       },
     ];
  return (
    <div className=' flex px-10 flex-col lg:flex-row mt-5 mb-5'>
      {/* First Row */}
      <div className='lg:w-[50%]'>
        <h1
          className={`${selectedLanguage === 1 ? 'text-[20px]' : null} ${
            selectedLanguage === 2 ? 'text-[20px]' : null
          } text-4xl text-left mb-10 font-bold`}
        >
          {text?.text1[selectedLanguage]}
        </h1>
        <div className='lg:w-[80%]'>
          <h1
            className={`  ${selectedLanguage === 1 ? 'text-[15px]' : null} ${
              selectedLanguage === 2 ? 'text-[16px]' : null
            } text-xl text-left mb-5`}
          >
            {text?.text2[selectedLanguage]}
          </h1>
        </div>

        <div className='flex flex-col lg:items-start items-start  md:justify-around '>
          {data?.map((item, index) => (
            <div
              key={index}
              className='py-5  lg:w-[80%] flex flex-col lg:flex-row justify-center items-center mb-5'
            >
              <h1 className='text-[60px] mr-5 font-light '>{item.icon}</h1>
              <div>
                <h1 className='text-xl font-bold'>
                  {item.text1[selectedLanguage]}
                </h1>
                <h1 className=' text-[15px] flex mt-2  text-left'>
                  {item.text2[selectedLanguage]}
                </h1>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Second Row */}
      <div className=' lg:w-[50%] mt-5 mb-5 rounded'>
        <img src={schedule} alt='Second ' className='w-full h-auto rounded' />
      </div>
    </div>
  );
}

export default WhyUs
