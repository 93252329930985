import React, {useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useLanguage } from '../../ContextProvider/Language';
import { logo } from '../../Assets';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'remixicon/fonts/remixicon.css';

const Header = () => {
  const [ismobile, setIsMobile] = useState(false);
  const { selectedLanguage, changeLanguage } = useLanguage();
  const [lang, setLang] = useState('English');
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const datas = [
    {
      img: 'https://www.sktperfectdemo.com/themepack/taxi/wp-content/themes/skt-taxi/images/slides/slider1.jpg',
    },
    {
      img: 'https://www.sktperfectdemo.com/themepack/taxi/wp-content/themes/skt-taxi/images/slides/slider2.jpg',
    },
    {
      img: 'https://www.sktperfectdemo.com/themepack/taxi/wp-content/themes/skt-taxi/images/slides/slider3.jpg',
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
  };

  const data = {
    home: ['Home', 'முகப்பு', 'ගෙදර'],
    about: ['About', 'எங்களை பற்றி', 'ගැන'],
    contact: ['Contact', 'தொடர்பு', 'අමතන්න'],
    service: ['Services', 'சேவைகள்', 'සේවා'],
    blog: ['Blog', 'வலைப்பதிவு', 'බ්ලොග්'],
    driver: ['Driver', 'Driver', 'Driver'],
    faq: ['FAQ', 'FAQ', 'FAQ'],
    calandar: ['Calendar', 'Calendar', 'Calendar'],
  };
  const text = {
    text1: [
      'JRide Taxi App-Beyond The Road',
      'JRide Taxi App-Beyond The Road',
      'JRide Taxi App-Beyond The Road',
    ],
    text2: [
      'Best Taxi App From Jaffna Srilanka',
      'Best Taxi App From Jaffna Srilanka',
      'Best Taxi App From Jaffna Srilanka',
    ],
    text3: [
      'Install & Enjoy The Ride Nation Wide',
      'Install & Enjoy The Ride Nation Wide',
      'Install & Enjoy The Ride Nation Wide',
    ],
  };

  const handleLanguageSelect = (language, lang) => {
    changeLanguage(language);
    setLang(lang);
    console.log('lang is', lang);
    setIsLanguageDropdownOpen(false);
  };

  return (
    <div className='bg-opacity-50'>
      <header className='hidden lg:block'>
        <div className='relative'>
          <div>
            <Slider {...settings}>
              {datas?.map((item, index) => (
                <img
                  key={index}
                  className=' shadow h-[500px] object-cover'
                  src={item.img}
                  alt='img'
                />
              ))}
            </Slider>
          </div>
          <div className='w-full  h-full absolute top-0'>
            <div className='flex items-center justify-center text-white h-full'>
              <div className=' flex flex-col items-center  '>
                <img className='h-[80px]' src={logo} alt='logo' />

                <h1 className={` font-poppins text-sm lg:font-bold lg:text-4xl text-yellow-300 `}>
                  {text.text1[selectedLanguage]}
                </h1>
                <h1 className=' font-poppins text-sm lg:font-bold lg:text-4xl m-2'>
                  {text?.text2[selectedLanguage]}
                </h1>
                <h1 className='font-poppins text-sm lg:font-bold lg:text-3xl m-2'>
                  {text?.text3[selectedLanguage]}
                </h1>
              </div>
            </div>
          </div>
          <nav className=' absolute z-10 top-0 mb-1 w-full border-gray-200  '>
            {/* header logo */}
            <div className='ml-2  flex  justify-between items-center mx-auto '>
              <Link to='/'>
                <div className='flex'>
                  <img className='h-14  ml-5 ' src={logo} alt='logo' />

                  {/* <img
                    src='https://tenor.com/view/new-year-2022-gif-24334949'
                    alt='Christmas GIF'
                    height='100%'
                    className=' w-[80px] ml-2 '
                  /> */}






                  
                </div>
              </Link>
              <div>
                <ul className='flex gap-5 mr-2'>
                  {/* Home NavLink */}
                  <li>
                    <NavLink
                      to=''
                      className={({ isActive }) =>
                        `block  rounded-b-lg h-[60px] duration-200    ${
                          isActive ? 'text-black bg-yellow-300' : 'text-white'
                        } lg:hover:bg-yellow-300 `
                      }
                    >
                      <div className='flex '>
                        <span className='p-3 text-navtext rounded hover:bg-yellow-300 hover:text-black'>
                          {data?.home[selectedLanguage]}
                        </span>
                      </div>
                    </NavLink>
                  </li>
                  {/* About navlink */}
                  <li>
                    <NavLink
                      to='/about'
                      className={({ isActive }) =>
                        `block  rounded-b-lg h-[60px]  duration-200 ${
                          isActive ? 'text-black bg-yellow-300' : 'text-white'
                        } lg:hover:bg-yellow-300 `
                      }
                    >
                      <div className='flex '>
                        <span className='p-3'>
                          {data?.about[selectedLanguage]}
                        </span>
                      </div>
                    </NavLink>
                  </li>
                  {/* Contact navlink */}
                  <li>
                    <NavLink
                      to='/contact'
                      className={({ isActive }) =>
                        `block  rounded-b-lg  h-[60px] duration-200 ${
                          isActive ? 'text-black bg-yellow-300' : 'text-white'
                        } lg:hover:bg-yellow-300 `
                      }
                    >
                      <div className='flex'>
                        <span className='p-3'>
                          {data?.contact[selectedLanguage]}
                        </span>
                      </div>
                    </NavLink>
                  </li>
                  {/* Services navlink */}
                  <li>
                    <NavLink
                      to='/service'
                      className={({ isActive }) =>
                        `block  rounded-b-lg h-[60px] duration-200 ${
                          isActive ? 'text-black bg-yellow-300' : 'text-white'
                        } lg:hover:bg-yellow-300 `
                      }
                    >
                      <div className='flex'>
                        <span className='p-3'>
                          {data?.service[selectedLanguage]}
                        </span>
                      </div>
                    </NavLink>
                  </li>
                  {/* Blog navlink */}
                  <li>
                    <NavLink
                      to='/blog'
                      className={({ isActive }) =>
                        `block rounded-b-lg h-[60px]  duration-200 ${
                          isActive ? 'text-black bg-yellow-300' : 'text-white'
                        } lg:hover:bg-yellow-300 `
                      }
                    >
                      <div className='flex'>
                        <span className='p-3'>
                          {data?.blog[selectedLanguage]}
                        </span>
                      </div>
                    </NavLink>
                  </li>

                  {/* Driver navlink */}
                  <li>
                    <NavLink
                      to='/driver'
                      className={({ isActive }) =>
                        `block rounded-b-lg h-[60px]  duration-200 ${
                          isActive ? 'text-black bg-yellow-300' : 'text-white'
                        } lg:hover:bg-yellow-300 `
                      }
                    >
                      <div className='flex'>
                        <span className='p-3'>
                          {data?.driver[selectedLanguage]}
                        </span>
                      </div>
                    </NavLink>
                  </li>

                  {/* FAQ navlink */}
                  <li>
                    <NavLink
                      to='/faq'
                      className={({ isActive }) =>
                        `block rounded-b-lg h-[60px]  duration-200 ${
                          isActive ? 'text-black bg-yellow-300' : 'text-white'
                        } lg:hover:bg-yellow-300 `
                      }
                    >
                      <div className='flex'>
                        <span className='p-3'>
                          {data?.faq[selectedLanguage]}
                        </span>
                      </div>
                    </NavLink>
                  </li>

                  {/* Calandar navlink */}
                  <li>
                    <NavLink
                      to='/calandar'
                      className={({ isActive }) =>
                        `block rounded-b-lg h-[60px]  duration-200 ${
                          isActive ? 'text-black bg-yellow-300' : 'text-white'
                        } lg:hover:bg-yellow-300 `
                      }
                    >
                      <div className='flex'>
                        <span className='p-3'>
                          {data?.calandar[selectedLanguage]}
                        </span>
                      </div>
                    </NavLink>
                  </li>

                  {/* Language Dropdown */}
                  <div className='relative p-3 text-white'>
                    <button
                      onClick={() =>
                        setIsLanguageDropdownOpen(!isLanguageDropdownOpen)
                      }
                      className='focus:outline-none text-navtext'
                    >
                      Language: {lang}
                      <i className='ri-arrow-down-s-line'></i>
                    </button>
                    {isLanguageDropdownOpen && (
                      <div className='absolute z-50 top-10 right-0 bg-white text-black shadow-md rounded-md p-2'>
                        <button
                          onClick={() => handleLanguageSelect(0, 'English')}
                          className='block w-full text-left py-1 px-2 hover:bg-gray-200'
                        >
                          English
                        </button>
                        <button
                          onClick={() => handleLanguageSelect(2, 'Sinhala')}
                          className='block w-full text-left py-1 px-2 hover:bg-gray-200'
                        >
                          Sinhala
                        </button>
                        <button
                          onClick={() => handleLanguageSelect(1, 'Tamil')}
                          className='block w-full text-left py-1 px-2 hover:bg-gray-200'
                        >
                          Tamil
                        </button>
                      </div>
                    )}
                  </div>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>

      {/* mobile view */}
      <div className='lg:hidden relative '>
        <div className='flex justify-between items-center m-3'>
          <div>
            <Link to='/'>
              <img className='h-10 ml-2 ' src={logo} alt='logo' />
            </Link>
          </div>
          <div className='text-[20px]' onClick={() => setIsMobile(true)}>
            <i class='ri-menu-line'></i>
          </div>
        </div>
        <div className=''>
          {ismobile && (
            <div className=' bg-yellow-500 z-[99999] absolute top-[-12px] left-0  w-full shadow '>
              <div
                className='text-[30px] text-red-500 flex justify-end mr-2'
                onClick={() => setIsMobile(false)}
              >
                <i class='ri-close-fill'></i>
              </div>

              <div>
                <ul className='flex flex-col m-5  '>
                  {/*home link */}
                  <Link to='/' onClick={() => setIsMobile(false)}>
                    <div className='flex bg-white rounded-lg mb-5 px-3 py-3 border-b shadow'>
                      <i class='ri-home-line'></i>
                      <li className='ml-2 font-semibold'>
                        {data?.home[selectedLanguage]}
                      </li>
                    </div>
                  </Link>
                  {/* about link */}
                  <Link to='/about' onClick={() => setIsMobile(false)}>
                    <div className='flex bg-white rounded-lg mb-5 px-3 py-3 border-b shadow'>
                      <i class='ri-survey-line'></i>
                      <li className='ml-2 font-semibold'>
                        {data?.about[selectedLanguage]}
                      </li>
                    </div>
                  </Link>

                  {/* services link */}
                  <Link to='/service' onClick={() => setIsMobile(false)}>
                    <div className='flex bg-white rounded-lg mb-5 px-3 py-3 border-b shadow'>
                      <i class='ri-service-line'></i>
                      <li className='ml-2 font-semibold'>
                        {data?.service[selectedLanguage]}
                      </li>
                    </div>
                  </Link>

                  {/* blog link */}
                  <Link to='/blog' onClick={() => setIsMobile(false)}>
                    <div className='flex bg-white rounded-lg mb-5 px-3 py-3 border-b shadow'>
                      <i class='ri-news-line'></i>
                      <li className='ml-2 font-semibold'>
                        {data?.blog[selectedLanguage]}
                      </li>
                    </div>
                  </Link>

                  {/* contact link */}
                  <Link to='/contact' onClick={() => setIsMobile(false)}>
                    <div className='flex bg-white rounded-lg mb-5 px-3 py-3 border-b shadow'>
                      <i class='ri-contacts-line'></i>
                      <li className='ml-2 font-semibold'>
                        {data?.contact[selectedLanguage]}
                      </li>
                    </div>
                  </Link>

                  {/* driver link */}
                  <Link to='/driver' onClick={() => setIsMobile(false)}>
                    <div className='flex  bg-white rounded-lg mb-5 px-3 py-3 border-b shadow'>
                      <i class='ri-steering-2-line'></i>
                      <li className='ml-2 font-semibold'>
                        {data?.driver[selectedLanguage]}
                      </li>
                    </div>
                  </Link>
                  {/* faq link */}
                  <Link to='/faq' onClick={() => setIsMobile(false)}>
                    <div className='flex  bg-white rounded-lg mb-5 px-3 py-3 border-b shadow'>
                      <i class='ri-question-answer-line'></i>
                      <li className='ml-2 font-semibold'>
                        {data?.faq[selectedLanguage]}
                      </li>
                    </div>
                  </Link>
                  {/* calandar link */}
                  <Link to='/calandar' onClick={() => setIsMobile(false)}>
                    <div className='flex  bg-white rounded-lg mb-5 px-3 py-3 border-b shadow'>
                      <i class='ri-calendar-schedule-line'></i>
                      <li className='ml-2 font-semibold'>
                        {data?.calandar[selectedLanguage]}
                      </li>
                    </div>
                  </Link>

                  <div className='relative p-3 bg-white rounded-lg mb-5 px-3 py-3 border-b shadow'>
                    <button
                      onClick={() =>
                        setIsLanguageDropdownOpen(!isLanguageDropdownOpen)
                      }
                      className='focus:outline-none text-navtext'
                    >
                      <i class='ri-speak-line'></i>{' '}
                      <span className='ml-1 font-semibold'>{lang}</span>
                      <i className='ri-arrow-down-s-line'></i>
                    </button>
                    {isLanguageDropdownOpen && (
                      <div className='absolute z-50 top-10 left-0 bg-white shadow-md rounded-md p-2'>
                        <button
                          onClick={() => handleLanguageSelect(0, 'English')}
                          className='block w-full text-left py-1 px-2 hover:bg-gray-200'
                        >
                          English
                        </button>
                        <button
                          onClick={() => handleLanguageSelect(2, 'Sinhala')}
                          className='block w-full text-left py-1 px-2 hover:bg-gray-200'
                        >
                          Sinhala
                        </button>
                        <button
                          onClick={() => handleLanguageSelect(1, 'Tamil')}
                          className='block w-full text-left py-1 px-2 hover:bg-gray-200'
                        >
                          Tamil
                        </button>
                        {/* Add more language options as needed */}
                      </div>
                    )}
                  </div>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
