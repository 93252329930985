import React from 'react';

const Deletereqest = () => {

const handleDownload = () => {
  // Create a new Blob object with the content you want to download
   const pdfUrl =
     'https://gdpr.eu/wp-content/uploads/2019/01/RIGHT-TO-ERASURE-REQUEST-FORM.pdf'; // Replace with the actual URL

   // Create a temporary <a> element to initiate the download
   const link = document.createElement('a');
   link.href = pdfUrl;
   link.target = '_blank'; // Open the PDF file in a new tab/window
   link.rel = 'noopener noreferrer'; // Security best practice
   document.body.appendChild(link);

   // Click the link to trigger the download
   link.click();

   // Clean up by removing the <a> element
   document.body.removeChild(link);
};



  return (
    <div className='lg:mx-20 mx-5 my-10'>
      <h1 className='mb-5'>
        You are entitled to request us to erase any personal data we hold about
        you under EU General Data Protection Regulation (GDPR).
      </h1>

      <h1 className='mb-5'>
        We will do our best to respond promptly and in any event within one
        month of the following:
      </h1>

      <ul className='ml-5 mb-5'>
        <li className='list-disc'>Our receipt of your written request; or</li>
        <li className='list-disc'>
          Our receipt of any further information we may ask you to provide to
          enable us to comply with your request, whichever happens to be later.
        </li>
      </ul>

      <h1 className='mb-5'>
        The information you supply in this form will only be used for the
        purposes of identifying the personal data you are requesting that we
        erase and responding to your request. You are not obliged to complete
        this form to make a request, but doing so will make it easier for us to
        process your request quickly.
      </h1>
      <div className='mb-5'>
        <h1 className='font-bold'>
          SECTION 1: Details of the person requesting information
        </h1>
        <h1>Full name:</h1>
        <h1>Address:</h1>
        <h1>Contact telephone number:</h1>
        <h1>Email address:</h1>
      </div>
      <div className='mb-5 '>
        <h1 className='mb-2 font-bold'>SECTION 2: Are you the data subject?</h1>
        <div>
          <h1 className='mb-2'>
            Please tick the appropriate box and read the instructions which
            follow it.
          </h1>
          <div className='ml-5 mb-5'>
            <div>
              <input type='checkbox' id='yes' name='yes' />
              <label for='yes' className='ml-2'>
                YES: I am the data subject. I enclose proof of my identity (see
                below). (Please go to Section 4)
              </label>
            </div>
            <div>
              <input type='checkbox' id='No' name='No' />
              <label for='No' className='ml-2'>
                NO: I am acting on behalf of the data subject. I have enclosed
                the data subject’s written authority and proof of the data
                subject’s identity and my own identity (see below). (Please go
                to Section 3)
              </label>
            </div>
          </div>

          <h1 className='mb-2'>
            To ensure we are erasing data of the right person we require you to
            provide us with proof of your identity and of your address. Please
            supply us with a photocopy or scanned image (do not send the
            originals) of one or both of the following:
          </h1>

          <ol className='ml-5 mb-5'>
            <li className='list-decimal'>
              <h1>
                Proof of Identity : Passport, photo driver’s license, national
                identity card, birth certificate.
              </h1>
            </li>
            <li className='list-decimal'>
              Proof of Address:Utility bill, bank statement, credit card
              statement (no more than 3 months old); current driver’s license;
            </li>
          </ol>

          <h1>
            If we are not satisfied you are who you claim to be, we reserve the
            right to refuse to grant your request.
          </h1>
        </div>
      </div>
      <div className='mb-5'>
        <h1 className='mb-2 font-bold'>
          SECTION 3: Details of the data subject (if different from section 1)
        </h1>
        <h1>Full name:</h1>
        <h1>Address:</h1>
        <h1>Contact telephone number:</h1>
        <h1>Email address:</h1>
      </div>
      <div className='mb-5'>
        <h1 className='mb-2 font-bold'>
          SECTION 4: Reason for erasure request
        </h1>
        <div>
          <h1>
            Given the sensitive nature of erasing personal data, GDPR Article
            17(1) requires certain conditions to be met before a request may be
            considered. Please supply us with the reason you wish your data to
            be erased and please attach any justifying documents to this one.
          </h1>
          <h1 className='mb-2'>Please tick the appropriate box:</h1>
          <div className='ml-5'>
            <div>
              <input type='checkbox' id='one' name='one' />
              <label for='one' className='ml-2'>
                You feel your personal data is no longer necessary for the
                purposes for which we originally collected it.
              </label>
            </div>
            <div>
              <input type='checkbox' id='two' name='two' />
              <label for='two' className='ml-2'>
                You no longer consent to our processing of your personal data.
              </label>
            </div>
            <div>
              <input type='checkbox' id='three' name='three' />
              <label for='three' className='ml-2'>
                You object to our processing of your personal data as is your
                right under Article 21 of the GDPR.
              </label>
            </div>
            <div>
              <input type='checkbox' id='four' name='four' />
              <label for='four' className='ml-2'>
                You feel your personal data has been unlawfully processed.
              </label>
            </div>
            <div>
              <input type='checkbox' id='five' name='five' />
              <label for='five' className='ml-2'>
                You feel we are subject to a legal obligation of the EU or
                Member State that requires the erasure of your personal data.
              </label>
            </div>
            <div>
              <input type='checkbox' id='six' name='six' />
              <label for='six' className='ml-2'>
                You are a child, you represent a child, or you were a child at
                the time of the data processing and you feel your personal data
                was used to offer you information society services.
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='mb-5'>
        <h1 className='mb-2 font-bold'>
          SECTION 5: What information do you wish to erase?
        </h1>
        <h1 className='mb-2'>
          Please describe the information you wish to erase. Please provide any
          relevant details you think will help us to identify the information.
          Providing the URL for each link you wish to be removed would be
          helpful.
        </h1>
        <h1 className='mb-2'>
          Also, please explain, if it is not abundantly clear, why the linked
          page is about you or the person you are representing on this form.
        </h1>
        <h1 className='mb-2'>
          Please note that. In certain circumstances, where erasure would
          adversely affect the freedom of expression, contradict a legal
          obligation, act against the public interest in the area of public
          health, act against the public interest in the area of scientific or
          historical research, or prohibit the establishment of a legal defense
          or exercise of other legal claims, we may not be able to erase the
          information you requested in accordance with article 17(3) of the
          GDPR. In such cases you will be informed promptly and given full
          reasons for that decision.
        </h1>
        <h1 className='mb-2'>
          While in most cases we will be happy to erase the personal data you
          request, we nevertheless reserve the right, in accordance with Article
          12(5) of the GDPR, to charge a fee or refuse the request if it is
          considered to be “manifestly unfounded or excessive.” However we will
          make every effort to provide you with the erasure of your personal
          data if suitable.
        </h1>
      </div>
      <div>
        <h1 className='mb-2 font-bold'>SECTION 6: Declaration</h1>
        <h1 className='mb-2'>
          Please note that any attempt to mislead may result in prosecution.
        </h1>
        <h1 className='mb-2'>
          I confirm that I have read and understood the terms of this subject
          access form and certify that the information given in this application
          to ______________ is true. I understand that it is necessary for
          ________________ to confirm my/the data subject’s identity and it may
          be necessary to obtain more detailed information in order to locate
          the correct personal data.
        </h1>
        <h1 className='mb-5'>Signed: ………………………………………… Date: ……………</h1>

        <h1 className='mb-2'>
          Documents which must accompany this application:
        </h1>
        <ul className='ml-5'>
          <li className='list-disc'>
            Evidence of your identity (see section 2)
          </li>
          <li className='list-disc'>
            Evidence of the data subject’s identity (if different from above)
          </li>
          <li className='list-disc'>
            Authorization from the data subject to act on their behalf (if
            applicable)
          </li>
          <li className='list-disc'>
            Justification for erasure of data (see section 4)
          </li>
        </ul>
      </div>

      <div className=' mt-5 p-5 rounded-lg hover:bg-blue-500 text-white bg-blue-300 w-fit'>
        <button onClick={handleDownload}>Download PDF</button>
      </div>
    </div>
  );
};

export default Deletereqest;
