import React from 'react'
import FAQS from '../Components/FAQ/FAQ'

const FAQ = () => {
  return (
    <div>
        <h1 className=' text-4xl font-bold text-center mt-10 mb-10 '>FAQ'S</h1>
      <FAQS />
    </div>
  )
}

export default FAQ
