import React from 'react'
import { useLanguage } from '../../ContextProvider/Language';

const Question = () => {
   const { selectedLanguage, changeLanguage } = useLanguage();
   const text = {
     text1: ['Have a Questions?', 'கேள்விகள் உள்ளதா?', 'ප්‍රශ්න තිබේද?'],
     text2: [
       'Our dedicated support team is here to assist you 24/7. Whether it is about your ride, app features, or anything in between, feel free to reach out.',
       '24/7 உங்களுக்கு உதவ எங்கள் அர்ப்பணிப்புள்ள ஆதரவுக் குழு இங்கே உள்ளது. உங்கள் சவாரி, ஆப்ஸ் அம்சங்கள் அல்லது இடையில் உள்ள எதையும் பற்றி தயங்காமல் தொடர்பு கொள்ளவும்.',
       '24/7 ඔබට සහය වීමට අපගේ කැපවූ සහායක කණ්ඩායම මෙහි සිටී. එය ඔබගේ සවාරිය, යෙදුම් විශේෂාංග හෝ ඒ අතර ඇති ඕනෑම දෙයක් ගැන වේවා, සම්බන්ධ වීමට නිදහස් වන්න.',
     ],
   };
    const data = [
      {
        text1: ['Our Address', 'எங்கள் முகவரி', 'අපගේ ලිපිනය'],
        text2: (
          <span>
            <h1>JRIDE Taxi Service</h1>
            <h1>Speed IT net</h1>
            <h1>PalalyRoad Thirunelvely</h1>
          </span>
        ),
        icon: <i class='ri-map-pin-line'></i>,
      },
      {
        text1: ['Our Phones', 'தொலைபேசிகள்', 'අපේ දුරකථන'],
        text2: (
          <span>
            <h1>0777 563213 </h1>
            <h1>0703563213 </h1>
          </span>
        ),
        icon: <i class='ri-phone-line'></i>,
      },
      {
        text1: ['Our Email', 'மின்னஞ்சல்', 'අපගේ විද්‍යුත් තැපෑල'],
        text2: 'hello@jride.lk',
        icon: <i class='ri-mail-unread-line'></i>,
      },
    ];
  return (
    <div className='mt-5 mb-5'>
      <h1
        className={`text-xl lg:text-5xl ${
          selectedLanguage === 1 ? 'lg:text-[25px]' : null
        } ${
          selectedLanguage === 2 ? 'lg:text-[25px]' : null
        } text-center mb-10 font-bold`}
      >
        {/* Have a Questions? */}
        {text?.text1[selectedLanguage]}
      </h1>
      <div className='lg:w-[80%] flex mx-auto '>
        <h1
          className={`text-[18px] lg:text-[${
            selectedLanguage === 1 ? '15px' : '20px'
          }] text-center mb-5 `}
        >
          {text?.text2[selectedLanguage]}
        </h1>
      </div>
      <div className='flex flex-col items-center md:items-center gap-2 lg:flex-row justify-center '>
        {data?.map((item, index) => (
          <React.Fragment key={index}>
            {index !== 0 && (
              <div className='hidden lg:block h-[200px] border-l border-gray-200'></div>
            )}
            <div
              key={index}
              className={`p-10 w-[250px] lg:h-[300px] flex flex-col items-center mb-5`}
            >
              <h1 className='text-[60px] font-light '>{item.icon}</h1>
              <h1
                className={`text-[20px] lg:text-[25px] ${
                  selectedLanguage === 1 ? 'lg:text-[15px]' : null
                } ${selectedLanguage === 2 ? 'lg:text-[15px]' : null} mb-3`}
              >
                {item.text1[selectedLanguage]}
              </h1>
              <h1 className='flex mt-2 '>{item.text2}</h1>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default Question
