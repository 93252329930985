import React from 'react';
import {calandar} from "../Assets/index"

const Calandar = () => {
  return (
    <div className='m-5 p-5 rounded shadow'>
      <h1 className='text-center text-xl font-bold lg:text-4xl mb-5'>
        JRide Calandar 2024
      </h1>
      <a href='https://heyzine.com/flip-book/1adee58780.html'>
        <div className='w-full hover:transform lg:hover:scale-105 transition-transform duration-300 ease-in-out '>
          <img
            className='flex mx-auto h-[450px] rounded-lg shadow'
            src={calandar}
            alt='calandar'
          />
        </div>
        <h1 className=' text-center text-blue-400 font-semibold font-2xl mt-2 '>
          Download Link..
        </h1>
      </a>
    </div>
  );
}

export default Calandar
