import React from 'react';
import { useLanguage } from '../../ContextProvider/Language';
import { driver } from '../../Assets';
import { SiHuawei } from 'react-icons/si';

const DriverApp = () => {
  const { selectedLanguage, changeLanguage } = useLanguage();
  const data = {
    text1: [
      'Download the app and all Taxi will be at your hand',
      'பயன்பாட்டைப் பதிவிறக்கவும், அனைத்து டாக்ஸிகளும் உங்கள் கையில் இருக்கும்',
      'යෙදුම බාගන්න, සියලුම කුලී රථ ඔබ අතේ ඇත',
    ],
    text2: [
      'Empower your commute with convenience. Download our app and elevate your travel experience  because every ride should be a smooth journey at your command',
      'உங்கள் பயணத்தை வசதியுடன் மேம்படுத்தவும். எங்கள் பயன்பாட்டைப் பதிவிறக்கி, உங்கள் பயண அனுபவத்தை உயர்த்திக் கொள்ளுங்கள், ஏனெனில் ஒவ்வொரு சவாரியும் உங்கள் கட்டளைப்படி சுமூகமான பயணமாக இருக்க வேண்டும்',
      'ඔබේ ගමන පහසුව සමඟ සවිබල ගන්වන්න. සෑම සවාරියක්ම ඔබගේ අණ පරිදි සුමට ගමනක් විය යුතු බැවින් අපගේ යෙදුම බාගත කර ඔබගේ සංචාරක අත්දැකීම ඉහළ නංවන්න',
    ],
    text3: ['Easy Searching', 'எளிதான தேடல்', 'පහසු සෙවීම'],
    text4: ['Quick Pickups', 'விரைவான பிக்அப்கள்', 'ඉක්මන් පිකප්'],
    text5: ['Inclusive Rates', 'உள்ளடக்கிய விலைகள்', 'ඇතුළත් මිල ගණන්'],
    text6: ['Rapid Rides', 'விரைவான சவாரிகள்', 'වේගවත් ධාවන'],
    text7: ['Android App on', 'Android App on', 'Android App on'],
    text8: ['Apps Store', 'Apps Store', 'Apps Store'],
    text9: ['Google Play', 'Google Play', 'Google Play'],
    text10: ['Download on', 'Download on ', 'Download on'],
    text11: ['App Gallery', 'App Gallery ', 'App Gallery'],
  };

  return (
    <div className='m-3 shadow p-5 rounded'>
      <div className='flex flex-col lg:flex-row  gap-5'>
        <div className='flex-1'>
          <img className='shadow-lg rounded' src={driver} alt='img' />
        </div>
        <div className='mx-5 flex  flex-1 flex-col justify-between items-left'>
          <div className='mb-5'>
            <h1
              className={`text-2xl lg:text-5xl ${
                selectedLanguage === 1 ? 'lg:text-[25px]' : null
              } font-bold`}
            >
              {/* Download the app and all Taxi will be at your hand */}
              {data?.text1[selectedLanguage]}
            </h1>
          </div>
          <p className='mb-10 text-[18px]'>
            {/* Empower your commute with convenience. Download our app and elevate
            your travel experience  because every ride should be a smooth
            journey at your command. */}
            {data?.text2[selectedLanguage]}
          </p>
          <div className='mb-10 text-xl'>
            <ul className='list-none'>
              <li>
                <i class='ri-arrow-drop-right-line'></i>
                {data?.text3[selectedLanguage]}
              </li>
              <li>
                <i class='ri-arrow-drop-right-line'></i>
                {data?.text4[selectedLanguage]}
              </li>
              <li>
                <i class='ri-arrow-drop-right-line'></i>
                {data?.text5[selectedLanguage]}
              </li>
              <li>
                <i class='ri-arrow-drop-right-line'></i>
                {data?.text6[selectedLanguage]}
              </li>
            </ul>
          </div>
          <div className='flex flex-col lg:flex-row gap-5'>
            <div>
              <a href='https://apps.apple.com/us/app/jride-sri-lanka-driver/id6473545384'>
                <span className='w-[200px]  lg:w-[210px] flex  items-center p-3 px-5 rounded-[50px] bg-yellow-500 hover:bg-black hover:text-white'>
                  <div className='text-4xl lg:text-5xl '>
                    <i class='ri-apple-fill'></i>
                  </div>
                  <div className=''>
                    <h1>{data?.text10[selectedLanguage]}</h1>
                    <h1 className='text-xl font-bold'>
                      {data?.text8[selectedLanguage]}
                    </h1>
                  </div>
                </span>
              </a>
            </div>
            <div>
              <a href='https://play.google.com/store/apps/details?id=com.speedIt.JRide.driver'>
                <span className='flex  items-center p-3 px-5 rounded-[50px] bg-yellow-500 hover:bg-black hover:text-white w-[210px]'>
                  <div className='text-4xl lg:text-5xl  '>
                    <i class='ri-google-play-fill'></i>
                  </div>
                  <div className=''>
                    <h1>{data?.text7[selectedLanguage]}</h1>
                    <h1 className='text-xl font-bold'>
                      {data?.text9[selectedLanguage]}
                    </h1>
                  </div>
                </span>
              </a>
            </div>
            <div>
              <a href='https://appgallery.huawei.com/app/C109541437'>
                <span className=' w-[210px] flex items-center p-3 px-5 rounded-[50px] bg-yellow-500 hover:bg-black hover:text-white'>
                  <div className='text-4xl lg:text-5xl  '>
                    <SiHuawei />
                  </div>
                  <div className=''>
                    <h1>{data?.text10[selectedLanguage]}</h1>
                    <h1 className='text-xl font-bold'>
                      {data?.text11[selectedLanguage]}
                    </h1>
                  </div>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverApp;
// https://jride.lk/Jride-passenger.jpg
