import React from 'react';

import { useLanguage } from '../../ContextProvider/Language';

const MobileSliderCards = () => {
  const { selectedLanguage, changeLanguage } = useLanguage();
  const introvariants = {
    animate: { opacity: 1, x: 0 },
  };

  const data = [
    {
      text1: ['Best Price', 'சிறந்த விலை', 'හොඳම මිල'],
      text2: ['Guaranteed', 'உத்தரவாதம்', 'සහතිකයි'],
      icon: <i class='ri-money-dollar-circle-line'></i>,
    },
    {
      text1: ['24/7 Customer', '24/7வாடிக்கையாளர்', '24/7 පාරිභෝගිකයා'],
      text2: ['Care Service', 'பராமரிப்பு சேவை', 'සත්කාර සේවාව'],
      icon: <i class='ri-calendar-schedule-line'></i>,
    },
    {
      text1: ['Home', 'வீடு', 'ගෙදර'],
      text2: ['Pickups', 'பிக்கப்ஸ்', 'පිකප්'],
      icon: <i class='ri-home-office-line'></i>,
    },
    {
      text1: ['Easy', 'முன்பதிவுகள்', 'පහසු'],
      text2: ['Bookings', 'சுலபம்', 'වෙන් කිරීම්'],
      icon: <i class='ri-calendar-line'></i>,
    },
  ];
  return (
    <div
      className='flex flex-col md:items-center lg:flex-row gap-5 '
      
    >
      {data?.map((item, index) => (
        <div
          className='bg-yellow-500 rounded-lg shadow p-10 w-[250px] h-[250px] flex flex-col justify-center items-center mb-5'
          
        >
          <h1 className='text-[60px] lg:text-[80px]  font-thin'>{item.icon}</h1>
          <h1 className='text-[20px] text-white'>
            {item.text1[selectedLanguage]}
          </h1>
          <h1 className=' text-[20px] lg:text-[30px] font-semibold text-white'>
            {item.text2[selectedLanguage]}
          </h1>
        </div>
      ))}
    </div>
  );
};

export default MobileSliderCards;
