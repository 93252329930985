import React from 'react';
import { useLanguage } from '../../ContextProvider/Language';
import {car,minicar,largecar,threewheel,van} from '../../Assets/index'

const Choosetaxi = () => {
  const { selectedLanguage, changeLanguage } = useLanguage();
  const data = [
    {
      model: 'car',
      BaseRate: '250',
      PerMile: '130',
      Passengers: '4',
      img: car,
    },
    {
      model: 'minicar',
      BaseRate: '150',
      PerMile: '130',
      Passengers: '3',
      img: minicar,
    },
    {
      model: 'largecar',
      BaseRate: '500',
      PerMile: '130',
      Passengers: '5',
      img: largecar,
    },
    {
      model: 'threewheel',
      BaseRate: '130',
      PerMile: '100',
      Passengers: '3',
      img: threewheel,
    },
    {
      model: 'van',
      BaseRate: '1000',
      PerMile: '130',
      Passengers: '10',
      img: van,
    },
    {
      model: 'van(8s)',
      BaseRate: '1000',
      PerMile: '130',
      Passengers: '8',
      img: van,
    },
  ];
  return (
    <div>
      <div className='text-center mb-10'>
        <h1 className='text-2xl mb-5 font-semibold'>Our Best </h1>
        <h1 className='text-4xl mb-5 font-bold '>Choose Vehicle</h1>
      </div>

      <div className='lg:flex  gap-3 justify-between '>
        {data?.map((item, index) => (
          <div
            key={index}
            className='border shadow-lg rounded mb-3 p-5 flex flex-col items-center'
          >
            <img src={item.img} alt='model' className='w-[300px] mb-5' />
            <h1 className='mb-5 font-bold'>{item.model}</h1>
            <div className='flex justify-between w-full mb-5'>
              <div className='text-[14px]'>
                <h1>Base Rate:</h1>
              </div>
              <div className='text-[14px]'>
                <h1>{item.BaseRate}</h1>
              </div>
            </div>
            <div className='flex justify-between w-full mb-5'>
              <div className='text-[14px]'>
                <h1>Per Mile/KM:</h1>
              </div>
              <div className='text-[14px]'>
                <h1>{item.PerMile}</h1>
              </div>
            </div>
            <div className='flex justify-between w-full mb-5'>
              <div className='text-[14px]'>
                <h1>Passengers:</h1>
              </div>
              <div className='text-[14px]'>
                <h1>{item.Passengers}</h1>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Choosetaxi;
