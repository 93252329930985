import React from 'react'
import { useLanguage } from '../../ContextProvider/Language';

const Offer = () => {
  const { selectedLanguage, changeLanguage } = useLanguage();
     const data = [
       {
         text1: ['Address Pickup', 'முகவரி பிக்அப்', 'ලිපිනය පිකප්'],
         text2: [
           ' Our taxis will pick you up from your specified address.',
           'உங்கள் குறிப்பிட்ட முகவரியிலிருந்து எங்கள் டாக்சிகள் உங்களை அழைத்துச் செல்லும்.',
           'අපගේ කුලී රථ ඔබගේ නිශ්චිත ලිපිනයෙන් ඔබව රැගෙන යනු ඇත.',
         ],
         icon: <i class='ri-map-pin-line'></i>,
       },
       {
         text1: [
           'Airport Transfer',
           'விமான நிலைய பரிமாற்றம்',
           'ගුවන් තොටුපළ මාරු',
         ],
         text2: [
           'Seamless transfers to and from the airport.',
           'விமான நிலையத்திற்கு மற்றும் விமான நிலையத்திலிருந்து தடையற்ற இடமாற்றங்கள்.',
           'ගුවන් තොටුපළට සහ ඉන් පිටතට බාධාවකින් තොරව මාරුවීම්.',
         ],
         icon: <i class='ri-flight-land-line'></i>,
       },
       {
         text1: ['Long Distance', 'நீண்ட தூரம்', 'දිගු දුර'],
         text2: [
           'Explore destinations beyond city limits.',
           'நகர எல்லைக்கு அப்பாற்பட்ட இடங்களை ஆராயுங்கள்',
           'නගර සීමාවෙන් ඔබ්බට ගමනාන්ත ගවේෂණය කරන්න',
         ],
         icon: <i class='ri-pin-distance-fill'></i>,
       },
       {
         text1: ['Taxi Tours', 'டாக்ஸி டூர்ஸ்', 'කුලී රථ චාරිකා'],
         text2: [
           'Discover the city with our guided taxi tours.',
           'எங்கள் வழிகாட்டப்பட்ட டாக்ஸி சுற்றுப்பயணங்கள் மூலம் நகரத்தைக் கண்டறியவும்',
           'අපගේ මාර්ගෝපදේශක කුලී රථ සංචාර සමඟ නගරය සොයා ගන්න',
         ],
         icon: <i class='ri-taxi-fill'></i>,
       },
     ];
     const text = {
       text1: ['Our Best Rides', 'எங்கள் சிறந்த சவாரிகள்', 'අපේ හොඳම සවාරි'],
       text2: [
         'What we offer?',
         'நாங்கள் அளிப்பது என்னவென்றால்?',
         'අපි ඉදිරිපත් කරන්නේ කුමක්ද?',
       ],
     };
  return (
    <div className='mt-5 mb-5'>
      <h1
        className={` text-xl lg:text-3xl ${
          selectedLanguage === 1 ? 'lg:text-xl' : null
        } ${
          selectedLanguage === 2 ? 'lg:text-xl' : null
        } text-center mb-2   font-semibold`}
      >
        {text?.text1[selectedLanguage]}
      </h1>
      <h1
        className={`text-2xl lg:text-5xl ${
          selectedLanguage === 1 ? 'lg:text-xl' : null
        }  ${
          selectedLanguage === 2 ? 'lg:text-xl' : null
        } text-center mb-10  font-bold`}
      >
        {text?.text2[selectedLanguage]}
      </h1>
      <div className='flex flex-col items-center md:items-center lg:flex-row md:justify-around lg:mx-10 gap-5'>
        {data?.map((item, index) => (
          <div
            key={index}
            className={`rounded-lg shadow border p-10 w-[250px] h-[300px] ${
              selectedLanguage === 1 ? 'h-[400px]' : null
            } ${
              selectedLanguage === 2 ? 'h-[400px]' : null
            } flex flex-col  items-center mb-5`}
          >
            <h1 className='text-[60px] font-light '>{item.icon}</h1>
            <h1
              className={`text-${
                selectedLanguage === 1 ? '15px' : '20px'
              } mt-3 font-bold hover:text-orange-600 `}
            >
              {item.text1[selectedLanguage]}
            </h1>
            <h1
              className={`  ${
                selectedLanguage === 1 ? 'text-[16px]' : null
              } ${selectedLanguage === 2 ? 'text-[16px]' : null}   mt-2`}
            >
              {item.text2[selectedLanguage]}
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Offer
