import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../ContextProvider/Language';

const SliderCards = () => {
  const { selectedLanguage, changeLanguage } = useLanguage();
  const introvariants = {
    animate: { opacity: 1, x: 0 },
  };

    const data = [
      {
        text1: ['Best Price', 'சிறந்த விலை', 'හොඳම මිල'],
        text2: ['Guaranteed', 'உத்தரவாதம்', 'සහතිකයි'],
        icon: <i class='ri-money-dollar-circle-line'></i>,
      },
      {
        text1: ['24/7 Customer', '24/7வாடிக்கையாளர்', '24/7 පාරිභෝගිකයා'],
        text2: ['Care Service', ' சேவை', 'සත්කාර සේවාව'],
        icon: <i class='ri-calendar-schedule-line'></i>,
      },
      {
        text1: ['Home', 'வீடு', 'ගෙදර'],
        text2: ['Pickups', 'பிக்கப்ஸ்', 'පිකප්'],
        icon: <i class='ri-home-office-line'></i>,
      },
      {
        text1: ['Easy', 'முன்பதிவுகள்', 'පහසු'],
        text2: ['Bookings', 'சுலபம்', 'වෙන් කිරීම්'],
        icon: <i class='ri-calendar-line'></i>,
      },
    ];
  return (
    <motion.div
      className='flex flex-col md:items-center lg:flex-row gap-5 text-'
      initial={{ opacity: 0, x: -500 }}
      whileInView='animate'
      transition={{ duration: 1, staggerChildren: 0.1 }}
      variants={introvariants}
    >
      {data?.map((item, index) => (
        <motion.div
          className='bg-yellow-500 rounded-lg shadow p-10 w-[250px] h-[250px] flex flex-col justify-center items-center mb-5'
          initial={{ opacity: 0, x: -500 }}
          whileInView='animate'
          transition={{ duration: 1, staggerChildren: 0.1 }}
          variants={introvariants}
        >
          <h1 className='text-[60px] lg:text-[80px]  font-thin'>{item.icon}</h1>
          <h1 className={`text-[20px] text-white  ${
              selectedLanguage === 2 ? 'lg:text-[15px]' : null
            } font-semibold text-white`}>
            {item.text1[selectedLanguage]}
          </h1>
          <h1
            className={`text-[20px] 'lg:text-[30px] ${
              selectedLanguage === 1 ? 'lg:text-[20px]' : null
            }  ${
              selectedLanguage === 2 ? 'lg:text-[15px]' : null
            } font-semibold text-white`}
          >
            {item.text2[selectedLanguage]}
          </h1>
        </motion.div>
      ))}
    </motion.div>
  );
}

export default SliderCards
