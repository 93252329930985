import React from 'react'
import { manual } from '../../Assets';

const IntroVideo = () => {
  return (
    <div className='flex flex-col lg:flex-row  '>
      {/* First Row */}
      <div className='lg:w-[50%] p-4'>
        {/* Image */}
        <div className='lg:w-[80%]'>
          <img src={manual} alt='Second' className='w-full h-auto rounded' />
        </div>
      </div>

      {/* Second Row */}
      <div className=' lg:w-1/2 h-auto'>
        <div className=' p-4 text-center  h-full flex flex-col items-start justify-between  '>
          <h1 className=' text-2xl lg:text-4xl font-bold mb-3 text-left '>
            Your first choice for traveling anywhere
          </h1>
          <h1 className='text-xl lg:text-2xl text-left mb-3'>
            We are specialized in providing a high quality service
          </h1>
          {/* YouTube Video */}
          <div className='flex border h-[150px] lg:h-[200px] shadow-lg rounded-lg  mb-3 bg-gray-100'>
            <div className='flex text-[15px] lg:text-[20px] flex-1 justify-center font-bold text-left p-3 items-center'>
              <h1 className='m-2'>
                We are specialized in providing a high quality service
              </h1>
            </div>
            <div className='flex-1 w-[50%] '>
              <iframe
                className='rounded-r-lg shadow w-full h-[150px] lg:h-[200px]'
                width='560'
                height='315'
                src='https://www.youtube.com/embed/Y-6yyXGgT1E?si=MpYGjvSQTPA5KVPo'
                title='YouTube video player'
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowfullscreen='true'
              ></iframe>
            </div>
          </div>

          <div>
            <div className='flex items-center'>
              <div className='rounded-[100%] mr-5 flex justify-center items-center p-3 bg-black text-white text-[30px]'>
                <i class='ri-phone-fill'></i>
              </div>
              <div className='text-[15px] lg:text-[20px] font-bold flex flex-col items-start '>
                <h1>Call Us Now!</h1>
                <h1 className='text-[18px] lg:text-[25px]'>0706563213</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroVideo
