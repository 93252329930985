import React from 'react';
import { motion } from 'framer-motion';

import { useNavigate } from 'react-router-dom';
import SliderCards from '../Components/SliderCards/SliderCards';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Offer from '../Components/Offer/Offer';
import AppDownload from '../Components/AppDownload/AppDownload';
import Question from '../Components/Question/Question';
import WhyUs from '../Components/WhyUs/WhyUs';
import Choosetaxi from '../Components/ChooseTaxi/Choosetaxi';
import { useLanguage } from '../ContextProvider/Language';
import Booknow from '../Components/BookNow/Booknow';
import IntroVideo from '../Components/IntroVideo/IntroVideo';
import AppInstructions from '../Components/AppInstructions/AppInstructions';
import MobileSliderCards from '../Components/SliderCards/MobileSlider';

const Home = () => {
  const navigate = useNavigate();
  const { selectedLanguage, changeLanguage } = useLanguage();
  const data = [
    {
      img: 'https://www.sktperfectdemo.com/themepack/taxi/wp-content/themes/skt-taxi/images/slides/slider1.jpg',
    },
    {
      img: 'https://www.sktperfectdemo.com/themepack/taxi/wp-content/themes/skt-taxi/images/slides/slider2.jpg',
    },
    {
      img: 'https://www.sktperfectdemo.com/themepack/taxi/wp-content/themes/skt-taxi/images/slides/slider3.jpg',
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };



   const introvariants={
    animate:{opacity: 1, x: 0 }
   }
   const instvariants = {
     animate: { opacity: 1, y: 0 },
   };
    







  console.log('selected lang', selectedLanguage);
  const text = {
    text1: [
      'JRide Taxi App-Beyond The Road',
      'JRide Taxi App-Beyond The Road',
      'JRide Taxi App-Beyond The Road',
    ],
    text2: [
      'Best Taxi App From Jaffna Srilanka',
      'Best Taxi App From Jaffna Srilanka',
      'Best Taxi App From Jaffna Srilanka',
    ],
    text3: [
      'Install & Enjoy The Ride Nation Wide',
      'Install & Enjoy The Ride Nation Wide',
      'Install & Enjoy The Ride Nation Wide',
    ],
  };

  const allService = {
    text: ['View All Services', 'அனைத்து சேவைகள்', 'සියලුම සේවාවන්'],
  };

  return (
    <div className='mb-20'>
      <div className=''>
        <div className='  h-full relative'>
          <div className='w-full  absolute bottom-[-20%] '>
            <div className='hidden lg:flex  justify-center   '>
              <SliderCards />
            </div>
          </div>
        </div>
        <div className='lg:hidden mt-5  '>
          <div className='relative'>
            <div>
              <Slider {...settings}>
                {data?.map((item, index) => (
                  <img
                    key={index}
                    className=' shadow'
                    src={item.img}
                    alt='img'
                  />
                ))}
              </Slider>
            </div>
            <div className='w-full  h-full absolute top-0'>
              <div className='flex items-center justify-center text-white h-full'>
                <div className=' flex flex-col items-center  '>
                  <h1 className=' font-poppins text-sm lg:font-bold lg:text-4xl text-yellow-300  '>
                    {text.text1[selectedLanguage]}
                  </h1>
                  <h1 className=' font-poppins text-sm lg:font-bold lg:text-4xl m-2'>
                    {text?.text2[selectedLanguage]}
                  </h1>
                  <h1 className='font-poppins text-sm lg:font-bold lg:text-3xl m-2'>
                    {text?.text3[selectedLanguage]}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='lg:hidden mt-5 flex  justify-center '>
          <MobileSliderCards />
        </div>
      </div>

      <div className='lg:mt-[15%] border rounded shadow-lg'>
        <div className='flex justify-center'>
          <Offer />
        </div>
      </div>
      <div>
        <div
          className='lg:mt-[5%] mt-20 text-center cursor-pointer'
          onClick={() => {
            navigate('/service');
          }}
        >
          <span className='p-5  font-semibold  px-8 rounded-[50px] bg-yellow-500 text-xl text-black hover:bg-black hover:text-white'>
            {allService?.text[selectedLanguage]}
          </span>
        </div>
      </div>
      <div className='lg:hidden mt-20 lg:mx-10 border shadow-lg rounded p-5'>
        <IntroVideo />
      </div>
      <div className='lg:hidden mt-20 lg:mx-10 border shadow-lg rounded p-5'>
        <AppDownload />
      </div>
      <motion.div
        className='hidden lg:flex lg:mt-12 mt-20 lg:mx-10 border shadow-lg rounded p-5'
        initial={{ opacity: 0, x: -800 }}
        whileInView='animate'
        transition={{ duration: 1 }}
        variants={introvariants}
      >
        <IntroVideo />
      </motion.div>
      <motion.div
        className='hidden lg:flex lg:mt-12 mt-20 lg:mx-10 border shadow-lg rounded p-5'
        initial={{ opacity: 0, x: 800 }}
        whileInView='animate'
        transition={{ duration: 1 }}
        variants={introvariants}
      >
        <AppDownload />
      </motion.div>
      <motion.div
        className='lg:mt-12 mt-20 lg:mx-10 border shadow-lg rounded p-5'
        initial={{ opacity: 0, y: 200 }}
        whileInView='animate'
        transition={{ duration: 1 }}
        variants={instvariants}
      >
        <AppInstructions />
      </motion.div>
      <div className='lg:mt-12 mt-20 lg:mx-10 border shadow-lg rounded p-5'>
        <div className='flex justify-center'>
          <WhyUs />
        </div>
      </div>
      <div className='lg:mt-12 mt-20 lg:mx-10 border shadow-lg rounded p-5'>
        <div className='flex justify-center'>
          <Choosetaxi />
        </div>
      </div>
      <div className='lg:mt-12 mt-20  py-5'>
        <div>
          <Booknow />
        </div>
      </div>
      <div className='lg:mt-12 mt-20 lg:mx-10 border shadow-lg rounded p-5'>
        <div className='flex justify-center'>
          <Question />
        </div>
      </div>
    </div>
  );
};

export default Home;
