import React from 'react'
import { largecar } from '../../Assets';
import './style.css';
import  { useRef, useEffect } from 'react';

const Booknow = () => {
  const booknowRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add a class to trigger the animation when the component is in view
            entry.target.classList.add('animate-right-to-left');
          }
        });
      },
      { threshold: 0.01 } // Adjust the threshold as needed
    );

    // Start observing the Booknow component
    if (booknowRef.current) {
      observer.observe(booknowRef.current);
    }

    // Cleanup the observer when the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array ensures this effect runs once

  return (
    <div className='flex flex-col lg:flex-row items-center justify-center bg-yellow-500'>
      {/* First Row */}
      <div className='lg:w-1/2 p-4 ml-10 text-center'>
        <h1 className='text-xl lg:text-5xl text-left font-bold mb-2'>
          Call now and book our taxi for your next ride
        </h1>
        <div className='flex mt-10 '>
          <div className=' text-white text-[18px] lg:text-xl font-bold px-10 bg-black rounded-[50px] p-5 hover:bg-white hover:text-black cursor-pointer'>
            <p>Book Taxi Now</p>
          </div>
        </div>
      </div>

      {/* Second Row */}
      <div ref={booknowRef} className='lg:w-1/2 p-4 '>
        {/* Image */}
        <img src={largecar} alt='Taxi Image' className='w-full h-auto' />
      </div>
    </div>
  );
}

export default Booknow
