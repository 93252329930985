import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Layout from './Layout';
import { Provider } from 'react-redux';
import Snowfall from 'react-snowfall';
import { LanguageProvider } from './ContextProvider/Language';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';

import { store } from './Features/store';
import {
  Home,
  Contact,
  Blog,
  Service,
  About,
  Policy,
  Driver,
  FAQ,
  Calandar,
  Deletereqest
} from './Pages/index';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout />}>
      <Route path='' element={<Home />} />
      <Route path='contact' element={<Contact />} />
      <Route path='about' element={<About />} />
      <Route path='blog' element={<Blog />} />
      <Route path='service' element={<Service />} />
      <Route path='privacy-policy/' element={<Policy />} />
      <Route path='driver' element={<Driver />} />
      <Route path='faq' element={<FAQ />} />
      <Route path='calandar' element={<Calandar />} />
      <Route path='request-data-delete' element={<Deletereqest />} />
      <Route path='*' element={<div>page not found</div>} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LanguageProvider>
        <RouterProvider router={router} />

        {/* <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            background: 'transparent',
          }}
        >
          <Snowfall />
        </div> */}
      </LanguageProvider>
    </Provider>
  </React.StrictMode>
);
