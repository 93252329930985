import React from 'react';

const JaffnaTaxiServiceBlogPost = () => {
  
  const post = {
    title: "Seamless Jaffna Taxi Service: A Passenger's Perspective",
    content: `
      Discover the convenience and comfort of our Jaffna taxi service as we navigate
      through the city's vibrant streets, offering seamless pick-up and drop-off
      experiences for passengers.

      The journey begins with a prompt and friendly pick-up at your specified location.
      Our professional drivers, familiar with Jaffna's roads, ensure a smooth start to
      your ride. Feel at ease as you settle into the well-maintained, air-conditioned
      vehicle, equipped for a comfortable journey.

      ...

      As we weave through the city's traffic, our drivers prioritize passenger safety
      and satisfaction. Whether you're headed to a business meeting, a cultural
      event, or a relaxing day out, our taxi service is tailored to meet your needs.

      Jaffna's bustling markets and serene parks become easily accessible with our
      efficient drop-off service. Rest assured that our drivers are well-versed in the
      city's geography, ensuring you reach your destination promptly.

      ...

      Our commitment to providing a reliable taxi service extends to every aspect of
      your journey. From courteous drivers to clean and well-maintained vehicles, we
      prioritize your comfort and convenience.

      Experience Jaffna with the assurance of a dependable taxi service. Whether you're
      a local or a visitor, let us handle your transportation needs, so you can focus
      on enjoying the moments that matter.
    `,
    author: 'Jaffna Taxi Services Team',
    date: 'December 13, 2023',
  };

  return (
    <div className='blog-post max-w-2xl mx-auto my-8 px-4'>
      <h1 className='text-4xl font-bold mb-4'>{post.title}</h1>
      <p className='text-gray-600 mb-4'>
        By {post.author} on {post.date}
      </p>
      <div
        className='prose text-justify'
        dangerouslySetInnerHTML={{ __html: post.content }}
      />
    </div>
  );
};

export default JaffnaTaxiServiceBlogPost;
