import React, { useState } from 'react';


const DriverVideos = () => {
    const [expandedQuestion, setExpandedQuestion] = useState(null);
    const handleQuestionClick = (index) => {
      setExpandedQuestion((prevIndex) => (prevIndex === index ? null : index));
    };

    const videos = [
      {
        title: ' How To Add A Vehicle',
        video: (
          <iframe
            className='rounded-lg shadow lg:w-[560px] w-[200px] lg:h-[315px] h-[100px]'
            src='https://www.youtube.com/embed/IR742l6o5rc?si=idfJbMehU_um5zf9'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen='true'
          ></iframe>
        ),
      },
      {
        title: ' How to Accept Rides in JRide ?',
        video: (
          <iframe
            className='rounded-lg shadow lg:w-[560px] w-[200px] lg:h-[315px] h-[100px] '
            src='https://www.youtube.com/embed/9sC3hhRmyAA?si=eu1CCqQ_ouMM0Qmz'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen='true'
          ></iframe>
        ),
      },
      {
        title: 'Driver கள் எப்படி online , Offline களை கையாள்வது?',
        video: (
          <iframe
            className='rounded-lg shadow lg:w-[560px] w-[200px] lg:h-[315px] h-[100px]'
            src='https://www.youtube.com/embed/ZmhqIouUlPo?si=NhSGGJxRPTLX2y7h'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen='true'
          ></iframe>
        ),
      },
      {
        title: 'How to update new Version JRide Driver App',
        video: (
          <iframe
            className='rounded-lg shadow lg:w-[560px] w-[200px] lg:h-[315px] h-[100px]'
            src='https://www.youtube.com/embed/Ex0dcB8Eg9U?si=8a3Dt7009-6ZKoO_'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen='true'
          ></iframe>
        ),
      },
      {
        title:
          'JRide Taxi ரைவர் அப்பில் எவ்வாறு Cache மெமறி கிளியர் பண்ணுவது ?',
        video: (
          <iframe
            className='rounded-lg shadow lg:w-[560px] w-[200px] lg:h-[315px] h-[100px]'
            src='https://www.youtube.com/embed/VH_xlB2onxc?si=5UAEIBhLOv-phlj7'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen='true'
          ></iframe>
        ),
      },
      {
        title: 'How to Complete A Ride in jride Driver App?',
        video: (
          <iframe
            className='rounded-lg shadow lg:w-[560px] w-[200px] lg:h-[315px] h-[100px]'
            src='https://www.youtube.com/embed/MmKFxb3Z964?si=0RsL5ptb8dVa3YtV'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen='true'
          ></iframe>
        ),
      },
    ];
  return (
    <div>
      <div className='rounded shadow p-5 m-2 mt-12 w-full   border'>
        <h1 className='font-bold text-xl text-center mb-10'>
          {' '}
          Video demonstrations{' '}
        </h1>
        <div className='flex flex-col gap-2 w-[100%] '>
          {videos?.map((video, index) => (
            <div key={index} className='border-b p-2'>
              <h1
                className='cursor-pointer font-semibold '
                onClick={() => handleQuestionClick(index)}
              >
                <span className='mr-2 text-red-500'>
                  <i class='ri-video-fill'></i>
                </span>
                {video.title}
              </h1>
              {expandedQuestion === index && (
                <div className='px-5 text-sm mt-5 w-full '>{video.video}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DriverVideos
