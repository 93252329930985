import React from 'react';
import DriverApp from '../Components/AppDownload/DriverApp';
import DriverInstructions from '../Components/AppDownload/DriverInstructions';
import DriverVideos from '../Components/Driver/DriverVideos';
import DriverBenifits from '../Components/Driver/DriverBenifits';

const Driver = () => {
  return (
    <div className='lg:m-5 p-5'>
      <h1 className='text-4xl mb-10 font-bold text-center '>
        Driver App Download
      </h1>
      <div className='hover:transform lg:hover:scale-105 transition-transform duration-300 ease-in-out'>
        <DriverApp />
      </div>
      <div className='hover:transform lg:hover:scale-105 transition-transform duration-300 ease-in-out'>
        <DriverInstructions />
      </div>
      <div className='hover:transform lg:hover:scale-105 transition-transform duration-300 ease-in-out'>
        <DriverBenifits />
      </div>

      <div className='hover:transform lg:hover:scale-105 transition-transform duration-300 ease-in-out'>
        <DriverVideos />
      </div>
    </div>
  );
};

export default Driver;

// <iframe width="560" height="315" src="https://www.youtube.com/embed/98MKERAwUuw?si=t8vOqtYgIJxWG9tR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
