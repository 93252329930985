import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useLanguage } from '../../ContextProvider/Language';


const DriverInstructions = () => {
  const { selectedLanguage, changeLanguage } = useLanguage();
  const text = {
    text1: [
      'if you have issues in Adding Vehicle',
      'உங்கள் Driver கணக்கு முழுமையாகக்கப்படவில்லை ஏதேனும் சிக்கல் இருப்பின் ',
      'if you have issues in Adding Vehicle',
    ],
    text2: [
      'Send All to one of the Following What app Numbers : 0703563213 /0706563213',
      ' 0706563213  என்ற வட்சப் இலக்கத்திற்கு  அனுப்பி வைத்தால் நாங்களே  சேர்த்து விடுவோம்',
      'Send All to one of the Following What app Numbers : 0703563213 /0706563213',
    ],
    text3: ['Mobile Number', 'கைபேசி எண்', 'Mobile Number'],
    text4: [
      'NIC Front & Back Side images',
      'அடையாள அட்டை',
      'NIC Front & Back Side images',
    ],
    text5: [
      'Driving Licence Front & Back images',
      'ரைவிங் லைசன்ஸ்',
      'Driving Licence Front & Back images',
    ],
    text6: [
      'Vehicle Tax Front Side image',
      'ரக்ஸ்',
      'Vehicle Tax Front Side image',
    ],
    text7: [
      'Vechicle Insurance Front Side image',
      'இன்சூரன்ஸ் ',
      'Vechicle Insurance Front Side image',
    ],
    text8: ['Vechile Front image', 'வாகன முன்படம்', 'Vechile Front image'],
    text9: [
      'We will Add and inform to you',
      'நாங்களே  சேர்த்து விடுவோம்',
      'We will Add and inform to you',
    ],
  };
  const data = {
    data: `<ol class="list-decimal p-2 ml-2">
            <li class="mb-2 ">Download JRide Driver & Delivery App </li>
            <li class="mb-2 ">
              Register Using your Sri Lanka Mobile Number with a secure
              Password.
            </li>
            <li class="mb-2 ">
              You will get OTP Code on Mobile for Verification
            </li>
            <li class="mb-2 ">
              Once Verified, Your Account can be accessed by you
            </li>
            <li class="mb-2 ">
              You need to Update your Profile using My Profile Section.
              Following Documents are Mandatory
              <ul class="list-disc ml-3">
                <li>NIC Front & Back Side images </li>
                <li>Driving Licence Front & Back images </li>
              </ul>
            </li>
            <li class="mb-2 ">
              Add your Vehicle via Add Vehicle Section. Following Documents are
              Mandatory
              <ul ul class="list-disc ml-3">
                <li>Vehicle Tax Front Side image</li>
                <li>Vehicle Insurance Front Side image</li>
                <li>Vehicle Front image</li>
              </ul>
            </li>
            <li class="mb-2 ">
              Once you've Added Vehicle, Status will be Pending
            </li>
            <li class="mb-2 ">
              Once We verify and Activate, Drivers Can be Online and will be
              Ready to accept Rides.
            </li>
            <li class="mb-2 ">
              Drivers can Add multiple Vehicles, but only One can be the
              Preferred Vehicle for Riding at a time.
            </li>
            <li class="mb-2 ">
              If Drivers Follow the Drivers Whatsapp Community, YouTube,
              Facebook Page, they can get More information
            </li>
          </ol>`,
  };
  return (
    <div className='m-3  mt-10 shadow p-5 rounded'>
      <div className='text-center lg:text-2xl font-bold'>
        How Drivers to Join and Add Vechicles in JRide Driver App
      </div>
      <div className='mt-10 font-semibold lg:flex gap-5'>
        <div className='lg:w-[70%] shadow-lg rounded p-5'>
          {ReactHtmlParser(data.data)}
        </div>

        <div className=' border lg:w-[30%] bg-yellow-500 text-white p-5 rounded shadow-lg'>
          <div className=''>
            <h1>{text.text1[selectedLanguage]} </h1>
            <h1>{text.text2[selectedLanguage]}</h1>
          </div>
          <div className='p-5 font-semibold flex justify-center'>
            <ol className='list-decimal mt-5'>
              <li className='mb-2'>{text.text3[selectedLanguage]}</li>
              <li className='mb-2'>{text.text4[selectedLanguage]}</li>
              <li className='mb-2'>{text.text5[selectedLanguage]}</li>
              <li className='mb-2'>{text.text6[selectedLanguage]}</li>
              <li className='mb-2'>{text.text7[selectedLanguage]}</li>
              <li className='mb-2'>{text.text8[selectedLanguage]}</li>
            </ol>
          </div>
          <h1>{text.text9[selectedLanguage]}</h1>
        </div>
      </div>
    </div>
  );
};

export default DriverInstructions;
