import React from 'react'

import JaffnaTaxiServiceBlogPost from '../Components/Blog/Blog';

const Blog = () => {
  return (
    <div>
      <JaffnaTaxiServiceBlogPost />
    </div>
  );
}

export default Blog
