import React from 'react'
import Offer from '../Components/Offer/Offer'

const Services = () => {
  return (
    <div>
      <div className='flex justify-center'>
        <Offer />
      </div>
    </div>
  );
}

export default Services
