import React from 'react'

const AppInstructions = () => {
  return (
    <div className=''>
      <div>
        <div className='text-center lg:text-2xl font-bold mb-10'>
          <h1>App Instructions Step By Step Video Demonstration</h1>
        </div>

        {/* YouTube Video */}
        <div className='lg:flex lg:justify-center gap-10'>
          <div className='flex border lg:w-[50%]   shadow-lg rounded-lg  mb-10 p-5'>
            <div className=' flex-col flex-1 '>
              <h1 className='text-center text-xl font-bold mb-5'>
                How to create account in JRide?
              </h1>
              <iframe
                className='rounded w-full'
                height='315'
                src='https://www.youtube.com/embed/Ksc0xX1OKYo?si=vhuGyU8I6VOZzToz'
                title='YouTube video player'
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowfullscreen='true'
              ></iframe>
            </div>
          </div>
          {/* YouTube Video */}
          <div className='flex border  lg:w-[50%]  shadow-lg rounded-lg  mb-10 p-5'>
            <div className=' flex-col flex-1 '>
              <h1 className='text-center text-xl font-bold mb-5'>
                How to book in JRide Taxi App?
              </h1>
              <iframe
                className='rounded w-full'
                height='315'
                src='https://www.youtube.com/embed/WDUNiU3ALf4?si=uFNgDfaIvFuyZ9XV'
                title='YouTube video player'
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowfullscreen='true'
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppInstructions
