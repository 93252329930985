import React from 'react';

const Policy = () => {
  return (
    <div className='m-20 p-5'>
      <h1 className=' text-2xl font-bold text-center mb-5'>
        Privacy Policy for  JRide Taxi App
      </h1>

      <h1 className='mb-5'>
        we take the privacy of our customers seriously.
        This Privacy Policy explains what personal information we collect when
        you use our taxi app, how we use it, and how we protect it.
      </h1>

      <h1 className='font-bold mb-2'>Personal Information We Collect</h1>
      <h1 className='mb-2'>
        When you use our taxi app, we may collect the following personal
        information:
      </h1>
      <div className='mb-5'>
        <ul className='list-disc'>
          <li>Your name, email address, and phone number</li>
          <li>Your location data</li>
          <li>Your payment information</li>
          <li>Your feedback and ratings of our taxi service</li>
          <li>Your communication with our customer support team</li>
          <li>
            We may also collect non-personal information such as your device
            type, operating system, and app usage data.
          </li>
        </ul>
      </div>

      <h1 className='font-bold'>How We Use Your Personal Information</h1>
      <h1 className='mb-5'>
        We use your personal information for the following purposes:
      </h1>
      <div className='mb-5'>
        <ul className='list-disc'>
          <li>
            To provide our taxi service to you, including processing your
            bookings and payments
          </li>
          <li>To improve our taxi service and tailor it to your preferences</li>
          <li>To respond to your inquiries and customer support requests</li>
          <li>
            To send you marketing communications about our taxi service (if you
            have given us permission to do so)
          </li>
          <li>To comply with legal and regulatory requirements</li>
          <li>How We Protect Your Personal Information</li>
          <li>
            We take appropriate technical and organizational measures to protect
            your personal information from unauthorized access, disclosure,
            alteration, or destruction. We use encryption technology to
            safeguard your payment information and we limit access to your
            personal information to authorized personnel only.
          </li>
        </ul>
      </div>

      <h1 className='font-bold '>Sharing Your Personal Information</h1>
      <h1>
        We may share your personal information with third-party service
        providers that help us deliver our taxi service, such as payment
        processors and mapping software providers. We require these service
        providers to maintain the same level of privacy protection as we do.
      </h1>
      <h1 className='mb-5'>
        We may also share your personal information with law enforcement
        authorities or other government bodies if required to do so by law or
        legal process.
      </h1>
      <h1 className='font-bold '>Your Rights</h1>
      <h1 className='mb-5'>
        You have the right to access, correct, or delete your personal
        information. You can also withdraw your consent to the use of your
        personal information for marketing purposes at any time. To exercise
        these rights, please contact us using the contact information provided
        below.
      </h1>
      <h1 className='font-bold '>Changes to this Privacy Policy</h1>
      <h1 className='mb-5'>
        We may update this Privacy Policy from time to time to reflect changes
        in our privacy practices. We will notify you of any significant changes
        by posting a notice on our website or within our taxi app.
      </h1>
      <h1 className='font-bold '>Contact Us</h1>
      <h1>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at hello@jride.lk Or 136,Palaly Road ,Thirunelvely Jaffna..
      </h1>
    </div>
  );
};

export default Policy;
