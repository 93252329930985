import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser';
import { useLanguage } from '../../ContextProvider/Language';

const DriverBenifits = () => {
  
   
     const data = {
       data: `<ol class="list-decimal p-2 ml-2">
            <li class="mb-2 "><span class='font-semibold'>30 Seconds Ring Alert on New Rides </span ></br> Receive immediate notifications with a 30-second ring alert for new ride requests.</li>
            <li class="mb-2 "><span class='font-semibold'>Detailed Ride Information Window </span></br> Get comprehensive details in the ride request window, including pickup and drop-off locations, distance, and earnings information.</li>
            <li class="mb-2 "><span class='font-semibold'> Efficient Ride Assignment</span></br>Smartly assign ride requests to drivers in the surrounding area. Notifications are sent simultaneously to 6 drivers, with subsequent rounds if needed. </li>
            <li class="mb-2 "><span class='font-semibold'>SMS Confirmation on Ride Acceptance</span> </br>Upon accepting a ride, receive an SMS with passenger details, ensuring information delivery even in case of internet issues. </li>
            <li class="mb-2 "><span class='font-semibold'>App Reliability with Refresh Button</span> </br> Use the refresh button to resume the app in the same status in case of structural issues due to device or internet problems.</li>
            <li class="mb-2 "><span class='font-semibold'> Start & Stop Waiting Option</span></br> Drivers can efficiently manage waiting times with the start and stop waiting options.</li>
            <li class="mb-2 "><span class='font-semibold'>Interactive Map with Location Details </span></br>Visualize ride details on the map with red (drop-off) and green (pickup) points. Tap icons for location names, and seamlessly switch to Google Maps for precise navigation. </li>
            <li class="mb-2 "><span class='font-semibold'>Low Power Consumption </span></br> Ensure a longer battery life with minimal power consumption, prioritizing driver convenience.</li>
            <li class="mb-2 "><span class='font-semibold'>Offline-Online Status Update</span> </br>Drivers can update their status and location offline, providing flexibility and convenience. </li>
            <li class="mb-2 "><span class='font-semibold'> Multilingual Interface</span></br>Access the app in three languages, catering to a diverse driver community in Sri Lanka. </li>
            <li class="mb-2 "><span class='font-semibold'>Vehicle Management</span> </br>Manage multiple vehicles in one account and easily switch between preferred vehicles. </li>
            <li class="mb-2 "><span class='font-semibold'>Competitive Rates </span></br> Enjoy high earnings with rates set according to the government-fixed standards.</li>
            <li class="mb-2 "><span class='font-semibold'> Low Commission</span></br>Benefit from a minimal 5% commission, providing an advantageous platform for drivers. </li>
            <li class="mb-2 "><span class='font-semibold'>User-Friendly GUI </span></br>Experience a visually appealing interface with vibrant colors and large fonts for better readability. </li>
            <li class="mb-2 "><span class='font-semibold'>No Cancellation Penalty</span> </br>Currently, there are no cancellation penalties for drivers, promoting flexibility. </li>
            <li class="mb-2 "><span class='font-semibold'> Secure Data Practices</span></br>Rest assured with security-checked data and ensured data privacy, with all information securely stored on the server. </li>
            <li class="mb-2 "><span class='font-semibold'> Dedicated Customer Care</span></br>Access responsive and supportive customer care services tailored to driver needs. </li>
            <li class="mb-2 "><span class='font-semibold'> Fast Account Activation</span></br> Submit all required documents, and your account will be activated within 5 minutes, eliminating unnecessary waiting times.</li>
            <li class="mb-2 "><span class='font-semibold'>Non-Intrusive App </span></br>Use other apps without disturbance, as the JRide app avoids loading sticky icons. </li>
            <li class="mb-2 "><span class='font-semibold'> Account Deletion Request</span></br>Drivers can request account deletion, and the customer care team will efficiently process the request. </li>
            <li class="mb-2 "><span class='font-semibold'>Timely Complaint Resolution</span> </br> Any driver complaints will be promptly addressed by the dedicated customer care team.</li>
            
          </ol>`,
     };
       const initialVisibleChars = 750;
       const [visibleChars, setVisibleChars] = useState(initialVisibleChars);

     const isShowMoreVisible = visibleChars < data.data.length;

      const showMore = () => {
        setVisibleChars(visibleChars + 2000);
      };

      const showLess = () => {
        setVisibleChars(initialVisibleChars);
      };

  return (
    <div>
      <div className='mt-10 shadow-lg rounded p-5 m-3'>
        <div className='text-center lg:text-2xl font-bold'>
          JRide Taxi App Special Features for Drivers
        </div>
        <div className='mt-10 '>
          <div className='mt-10 '>
            {ReactHtmlParser(data.data.slice(0, visibleChars))}
          </div>
          {isShowMoreVisible && (
            <div className='text-center mt-4 '>
              <button className='text-blue-500' onClick={showMore}>
                Show More
              </button>
            </div>
          )}
          {!isShowMoreVisible && visibleChars > initialVisibleChars && (
            <div className='text-center mt-4 '>
              <button className='text-blue-500' onClick={showLess}>
                Show Less
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DriverBenifits
